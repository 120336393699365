<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10  ">Nuevo Usuario</h1>
        <hr class="">
      </v-col>
    </v-row>
    <v-form ref="form" @submit.prevent="submitForm">
      <v-row class="mx-sm-8 mx-2 mt-10">
        <v-col cols="12" lg="6">
          <v-text-field
              v-model="data.fullName"
              label="Nombre Completo"
              placeholder="Nombre Completo"
              :rules="[rules.required]"
              outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6">
          <v-text-field
              v-model="data.email"
              label="Correo"
              placeholder="Correo"
              :rules="[rules.required, rules.email]"
              outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6">
          <p >Contraseña</p>
          <v-text-field
              outlined
              v-model="data.password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              :rules="[rules.required]"
              @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6">
          <p >Confirmar contraseña</p>
          <v-text-field
              outlined
              v-model="data.password_confirmed"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              :rules="[rules.required]"
              @click:append="show2 = !show2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="6">
          <v-select
              v-model="data.asejDepartamentoId"
              :items="departamentsList"
              label="Departamento"
              no-data-text="Sin Resultados"
              :rules="[rules.required]"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>

        </v-col>
        <v-col cols="12">
          <v-btn type="submit" color="primary" style="width: 200px">
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>


  </div>

</template>

<script>
import {mapActions} from "vuex";
import Overlay from "../../components/Overlay";

export default {
  name: "CreateUsers",
  data() {
    return {
      departaments: [],
      departamentsList: [],
      overlay: false,
      show1: false,
      show2: false,
      data: {
        fullName: "",
        asejDepartamentoId: "",
        password: "",
        password_confirmed: "",
        email: "",
      },
      rules: {
        required: value => !!value || 'Este campo es requerido.',
        email: value => {
          const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
          return pattern.test(value) || 'El correo no es válido.';
        },
      },
    };
  },
  components: {
    Overlay
  },
  async created() {
    await this.findAll();

  },
  methods: {
    ...mapActions('users', ['registerUser','findDepartments']),
    async findAll() {
      this.departamentsList = []
      const response = await this.findDepartments();
      for (const a of response){
        this.departamentsList.push({
          text: a.nombre,
          value: a.id,
        })
      }

    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        if (this.data.password !== this.data.password_confirmed){
          this.sweetAlertError('Contraseñas no coinciden')
          return
        }
        this.overlay = true;
        const response = await this.registerUser(this.data);
        if (!response.error) {
          this.overlay = true;
          this.sweetAlertSuccess('Se creo correctamente')
          await this.$router.push('/usuarios')
        } else {
          this.overlay = false;
          this.sweetAlertError(response.error)
        }
      }
    },
  },
}
</script>

<style scoped>
.v-list-item__title{
  color: black !important;
}
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: black !important;
  text-align: left;
}
</style>
